import 'boxicons'

const init = () => {
  const $watermark = document.getElementById('developer-watermark')

  $watermark.innerHTML =
    'Was developed by <a href="http://giraffic-design.com" style="color: #aaa; text-decoration: underline;">Giraffic Design</a>'
  $watermark.style = 'color: gray;'
}

window.onload = init
window.addEventListener('sveltekit:navigation-start', init)
window.addEventListener('sveltekit:trigger_prefetch', init)
